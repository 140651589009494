.dragOver {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.5);
  border: 1em dashed white;
  display: table;
}

.dragOverContent {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 10em;
  color: white;
  line-height: 100%;
}

a {
  cursor: pointer;
}

body {
  margin: 14px;
  font-size: 16px;
}

.uploads {
  margin-bottom: 14px;
}

.ui.progress:last-child {
  margin-bottom: 0;
}

.finished-uploads {
  margin-bottom: 14px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.error.message {
  display: block !important;
}

.tukurjub-modal {
  position: unset !important;
  margin: 5vh auto;
  @media (max-width: 767px) {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

.tukurjub-modal-wrapper {
  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .tukurjub-modal {
    display: flex !important;
    flex-direction: column;
  }
  .content {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .button {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.finished-uploads table {
  width: 100%;
}

.finished-uploads table.mobile-view {
  table-layout: fixed;

  .finished-upload__url {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.finished-upload__filename {
  word-wrap: break-word;
  hyphens: auto;
}

.ui.button {
  font-size: inherit;
}

button.copyToClipboard {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4183c4;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &:focus {
    animation: greenHighlight 1s ease-in-out forwards;
  }
  &:active {
    animation: none;
  }
}

@keyframes greenHighlight {
  0% {
    color: #21ba45;
  }
  100% {
    color: #4183c4;
  }
}
